import {Component, OnInit} from '@angular/core';
import {UsuarioService} from './core/services/admin/usuario/usuario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
      public usuarioService: UsuarioService
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('token') === '') {
      localStorage.setItem('token', '');
    }
    if (localStorage.getItem('usuario') === '') {
      localStorage.setItem('usuario', '' );
    } else {
      this.usuarioService.cargarStorage();
    }
  }
}
