/*
* Seremac-frontend - core/models/usuario.model.ts
* @Autor: hburgos@sofdev.cl - www.sofdev.cl
* @Version: 1.0.0
* fecha: 10-2021
*/

export class Usuario {
    constructor(
        public nombre: string,
        public email: string,
        public password: string,
        public estado: number,
        public role: string,
        public telefono?: number,
        public rut?: string,
        public img?: string,
        public cargo?: string,
        public descripcion?: string,
        public nombreEstado?: string,
        public nombreRole?: string,
        public id?: number
    ) {
    }
}
