import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styles: [
  ]
})
export class BreadcrumsComponent implements OnDestroy {
  // public icon: string;
  // public label: string;
  // public titulo: string;
  // public pages: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() pages: string;
  public tituloSubs$: Subscription;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private title: Title,
      private meta: Meta
  ) {
      this.title.setTitle( this.label );

      const metaTag: MetaDefinition = {
          name: 'description',
          content: this.label
      };

      this.meta.updateTag(metaTag);
      // this.getDataRoute()
      //     .subscribe( data => {
      //
      //         // this.label = data.titulo;
      //         // this.icon = data.icon;
      //         // this.pages = data.pages;
      //         this.title.setTitle( this.label );
      //
      //         const metaTag: MetaDefinition = {
      //             name: 'description',
      //             content: this.label
      //         };
      //
      //         this.meta.updateTag(metaTag);
      //
      //     });
  }
  ngOnDestroy(): void {
    // this.tituloSubs$.unsubscribe();
  }

  getArgumentosRuta() {

    return this.router.events
        .pipe(
            filter( event => event instanceof ActivationEnd ),
            filter( (event: ActivationEnd) => event.snapshot.firstChild === null  ),
            map( (event: ActivationEnd) => event.snapshot.data ),
        );
  }

    getDataRoute() {

        return this.router.events
            .pipe(
                filter( evento => evento instanceof ActivationEnd  ),
                filter( (evento: ActivationEnd) => evento.snapshot.firstChild === null ),
                map( (evento: ActivationEnd) => evento.snapshot.data )
            );

    }

}
