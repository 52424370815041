import { Injectable } from '@angular/core';
import {Usuario} from '../../../models/usuario.model';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {URL_SERVICIOS} from '../../../config/config';
import Swal from 'sweetalert2';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

export interface UsuarioToken {
  id: number;
  nombre: string;
  role: string;
  estado: number;
}

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  totalUsuarios: number;
  // @ts-ignore
  usuario: Usuario = new Usuario();
  token = '';
  constructor(
      public http: HttpClient,
      public router: Router
  ) {
      // this.usuario = JSON.parse( localStorage.getItem('usuario') );
      if (localStorage.getItem('token')) {
          this.token = localStorage.getItem('token');
      }
  }

  renuevaToken(usuario: UsuarioToken) {

    let url = URL_SERVICIOS + '/login/renueva_token/usuario/' + usuario;
    url += '?token=' + this.token;

    return this.http.get( url )
        .map( (resp: any) => {

          this.token = resp.token;
          localStorage.setItem('token', this.token );
          console.log('Token renovado');
          return true;
        })
        .catch( err => {
          this.router.navigate(['/login']);
          Swal.fire( 'No se pudo renovar token', 'No fue posible renovar token', 'error' );
          return Observable.throw( err );
        });


  }

  estaLogueado() {
    console.log(this.usuario);
    if (!localStorage.getItem('token')) {
        localStorage.setItem('token', ' ');
    }
    return ( localStorage.getItem('token').length > 5 ) ? true : false;
    // return true;
  }

  cargarStorage() {

    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse( localStorage.getItem('usuario') );
      // this.menu = JSON.parse( localStorage.getItem('menu') );
    } else {
      this.token = '';
      this.usuario = null;
    }

  }

  guardarStorage( id: number, token: string, usuario: Usuario ) {
    const iD = String(id);
    localStorage.setItem('id', iD );
    localStorage.setItem('token', token );
    localStorage.setItem('usuario', JSON.stringify(usuario) );
    sessionStorage.setItem('idUsuario', iD);
    //
    // this.usuario = usuario;
    // this.token = sessionStorage.getItem('token');
    // console.log(this.usuario);
  }

  logout() {
      Swal.fire({
          title: '¿Está seguro?',
          text: 'Esta a punto de cerrar sesión',
          icon: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#f60202',
          // cancelButtonColor: 'rgba(12,10,10,0.66)',
          confirmButtonText: 'Cerrar Sesión!',
          cancelButtonText: 'Cancelar'
      }).then(result => {
          if (result.value) {
              // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              this.usuario = null;
              this.token = '';
              this.borrarStorage();

              this.router.navigate(['/auth/login']);
          }
      });
  }

  logoutForzado() {
    this.usuario = null;
    this.token = '';

    this.borrarStorage();

    this.router.navigate(['/auth/login']);
  }

  borrarStorage() {
    /*--- local storage ---*/
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('menu');
    localStorage.removeItem('pb_forms');
    localStorage.removeItem('pb_exceptions');
    localStorage.removeItem('pb_user_activity');
    // localStorage.removeItem('email');
    localStorage.removeItem('user');
    localStorage.removeItem('id');
    /*--- session storage ---*/
    sessionStorage.removeItem('idUsuario');
  }

  login(usuario: Usuario, recordar: boolean = false) {

    if ( recordar ) {
      localStorage.setItem('email', usuario.email );
    } else {
      localStorage.removeItem('email');
    }

    const url = URL_SERVICIOS + '/login';
    return this.http.post( url, usuario )
        .map( (resp: any) => {
          console.warn(resp);
          this.token = resp.token;
          this.usuario = resp.usuario;
          this.guardarStorage( resp.id, resp.token, resp.usuario );
          return resp;
        })
        .catch( err => {
          Swal.fire( 'Login!', err, 'error' );
          return Observable.throw( err );
        });

  }

  crearUsuario( usuario: Usuario ) {

    const url = URL_SERVICIOS + '/usuario';

    return this.http.post( url, usuario )
        .map( (resp: any) => {

          Swal.fire('Usuario creado', usuario.email, 'success' );
          return resp.usuario;
        })
        .catch( err => {
          Swal.fire( err.error.mensaje, err.error.errors.message, 'error' );
          return Observable.throw( err );
        });
  }

  cambiarImagen( archivo: File, id: number ) {
    // const ID = String(id);
    // this.subirArchivoService.subirArchivo( archivo, 'usuarios', id )
    //     .then( (resp: any) => {
    //       this.usuario.img = resp.usuarios.img;
    //       swal( 'Imagen Actualizada', this.usuario.nombre, 'success' );
    //       this.guardarStorage( id, this.token, this.usuario, this.menu );
    //
    //     })
    //     .catch( resp => {
    //       console.log( resp );
    //     }) ;

  }

  cargarUsuario(id: number) {
    let url = URL_SERVICIOS + '/usuario/' + id;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => resp.usuarios)
        .catch(err => {
          Swal.fire('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  guardarUsuario( usuario: Usuario) {

    let url = URL_SERVICIOS + '/usuario';
    if (usuario.id) {
      /*- actualizando Usuarios **/
      url += '/' + usuario.id;
      url += '?token=' + this.token;

      return this.http.put(url, usuario)
          .map((resp: any) => {
            if ( usuario.id === this.usuario.id ) {
              const usuarioDB: Usuario = resp.usuarios;
              this.guardarStorage( usuarioDB.id, this.token, usuarioDB );
              console.log('Usuario actualizado');
              if (this.usuario === usuario) {
                  // localStorage.setItem('usuario', JSON.stringify(usuario) );
                  this.usuario = usuario;
              }
            }
            Swal.fire('Usuario actualizado', usuario.nombre, 'success');
            return resp.usuarios;
          })
          .catch(err => {
            Swal.fire('Error al actualizar!', 'No fue posible actualizar el registro. ' + err.error.mensaje, 'error');
            return Observable.throw(err);
          });
    } else {
      // /*- creando Usuarios **/
      // url += '?token=' + this.token;
      // return this.http.post(url, usuario)
      //     .map((resp: any) => {
      //       Swal.fire('Usuario Creado', usuario.nombre, 'success');
      //       return resp.usuarios;
      //     })
      //     .catch(err => {
      //       Swal.fire('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
      //       return Observable.throw(err);
      //     });
        /*- creando Cargos **/
        url += '?token=' + this.token;
        return this.http.post(url, usuario)
            .map((resp: any) => {
                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: 'Registro creado!',
                    text: 'Se ha creado el registro' + usuario.nombre + ' correctamente',
                    showConfirmButton: false,
                    timer: 1500
                });
                return resp.cargos;
            })
            .catch(err => {
                Swal.fire('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }
  }

  cambiarPassword(usuario: Usuario, pass: any) {
    let url = URL_SERVICIOS + '/usuario/password/' + usuario.id;
    url += '?token=' + this.token;
    return this.http.put(url, pass)
        .map( (resp: any) => {
          // this.totalUsuarios = resp.total;
          return resp.mensaje;
        })
        .catch( err => {
          Swal.fire('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  resetPassword(usuario: Usuario) {
    let url = URL_SERVICIOS + '/usuario/resetPassword/' + usuario.id;
    url += '?token=' + this.token;
    return this.http.put(url, usuario)
        .map( (resp: any) => {
          // this.totalUsuarios = resp.total;
          console.log(resp);
          return resp.mensaje;
        })
        .catch( err => {
          Swal.fire('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  listarUsuarios( desde: number ) {
    console.log( 'desde: ' + desde);
    let url = URL_SERVICIOS + '/usuario/desde/' + desde;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => {
          this.totalUsuarios = resp.total;
          return resp.usuarios;
        })
        .catch(err => {
          Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  listar() {
    let url = URL_SERVICIOS + '/usuario';
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => {
          this.totalUsuarios = resp.total;
          return resp.usuarios;
        })
        .catch(err => {
          Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

    listarCapataz() {
        let url = URL_SERVICIOS + '/usuario/capataz';
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.usuarios;
            })
            .catch(err => {
                Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

  listarPorEstado(estado: number) {
    let url = URL_SERVICIOS + '/v_usuario/estado/' + estado;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => {
          this.totalUsuarios = resp.total;
          return resp.v_usuarios;
        })
        .catch(err => {
          Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  listarVistaPorRole(role: string) {
    let url = URL_SERVICIOS + '/v_usuario/role/' + role;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => {
          this.totalUsuarios = resp.total;
          return resp.v_usuarios;
        })
        .catch(err => {
          Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  listarVistaPorNombre( nombre: string) {
    let url = URL_SERVICIOS + '/v_usuario/nombre/' + nombre;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map( (resp: any) => {
          this.totalUsuarios = resp.total;
          return resp.v_usuarios;
        })
        .catch( err => {
          Swal.fire('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  borrarUsuario(id: number) {
    let url = URL_SERVICIOS + '/usuario/' + id;
    url += '?token=' + this.token;

    return this.http.delete(url)
        .map(resp => {
          Swal.fire('Usuario Borrado', 'usuario borrado correctamente', 'success');
          return resp;
        })
        .catch(err => {
          Swal.fire('Error al borrar!', 'No fue posible borrar el registro. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  passwordDefault(usuario: Usuario) {
    let url = URL_SERVICIOS + '/login/default';
    url += '?token=' + this.token;
    return this.http.post(url, usuario)
        .map( (resp: any) => {
          // console.log(resp);
          return resp.status;
        })
        .catch( err => {
          Swal.fire('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  /*--- recuperación de contraseña ---*/
  mostrarPorEmail(email: string) {
    let url = URL_SERVICIOS + '/usuario/email/' + email;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => {
          this.totalUsuarios = resp.total;
          return resp;
        })
        .catch(err => {
          Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }

  recuperarPassword(email: string) {
    let url = URL_SERVICIOS + '/usuario/recuperar_password/' + email;
    url += '?token=' + this.token;
    return this.http.get(url)
        .map((resp: any) => {
          this.totalUsuarios = resp.total;
          return resp;
        })
        .catch(err => {
          Swal.fire('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
          return Observable.throw(err);
        });
  }
}
