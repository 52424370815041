import { Component, OnInit } from '@angular/core';
// import {version} from '../../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // VERSION: string = version;
  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(
  ) { }

  ngOnInit() {
  }

}
