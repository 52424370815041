import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import {NoPageFoundComponent} from './shared/no-page-found/no-page-found.component';
import {PagesRouting} from './pages/pages.routing';
import {LoginGuard} from './core/guards/login.guard';
import {VerificaTokenGuard} from './core/guards/verifica-token.guard';

const routes: Routes = [
  { path: 'auth',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: '',
    canActivate: [VerificaTokenGuard],
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule)},
  {path: '**', component: NoPageFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
