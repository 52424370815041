<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <li class="menu-title" >MENÚ</li>
      <!-- inicio -->
      <li class="sub-menu active">
        <a [routerLink]="['/']"  routerLinkActive="active" >
          <i class="bx bxs-home"></i>
          <span>Inicio</span>
        </a>
      </li>
      <li  *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a routerLinkActive="activeItem" [routerLink]="['/proyectos']" >
          <i class="bx bxs-briefcase"></i>
          <span>Proyectos</span>
        </a>
      </li>
      <li  *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
        <a routerLinkActive="activeItem" [routerLink]="['/mantenedores/usuario-proyectos']" >
          <i class="bx bxs-briefcase"></i>
          <span>Usuarios de los Proyectos</span>
        </a>
      </li>
      <!-- Layouts menu -->
<!--      <li >-->
<!--        <a routerLinkActive="active" class="has-arrow">-->
<!--          <i class="bx bxs-briefcase"></i>-->
<!--          <span>Proyectos</span>-->
<!--        </a>-->
<!--        <ul class="sub-menu" aria-expanded="false">-->
<!--          <li><a [routerLink]="['/proyectos/proyecto']"-->
<!--                 routerLinkActive="active" >-->
<!--            <i class="bx bx-add-to-queue"></i> Nuevo</a>-->
<!--          </li>-->
<!--          <li><a [routerLink]="['/proyectos']"-->
<!--                 routerLinkActive="active" >-->
<!--            <i class="bx bxs-folder-open"></i> En proceso</a>-->
<!--          </li>-->
<!--          <li><a [routerLink]="['/proyectos']"-->
<!--                 routerLinkActive="active" >-->
<!--            <i class="bx bx-lock"></i> Cerrados</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </li>-->
<!--      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">-->
<!--        <a [routerLink]="['cuadernillos/']" routerLinkActive="activeItem" >-->
<!--          <i class="bx bxs-book"></i>-->
<!--          <span>Cuadernillos</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'" >-->
<!--        <a [routerLink]="['cartas-ritmicas/']" routerLinkActive="activeItem" >-->
<!--          <i class="bx bxs-calendar-check"></i>-->
<!--          <span>Cartas Rítmicas</span>-->
<!--        </a>-->
<!--      </li>-->
      <li >
        <a [routerLink]="['vales/vales/']" routerLinkActive="activeItem" >
          <i class="bx bxs-file"></i>
          <span>Vales de consumo</span>
        </a>
      </li>

      <li class="menu-title" >Reportes</li>
      <li >
        <a [routerLink]="['reportes/materiales/']" routerLinkActive="activeItem" >
          <i class="bx bxs-bar-chart-square"></i>
          <span>Entrega de materiales</span>
        </a>
      </li>
      <li >
        <a [routerLink]="['reportes/reporte-actividades/']" routerLinkActive="activeItem" >
          <i class="bx bxs-bar-chart-square"></i>
          <span>Reporte por Actividad</span>
        </a>
      </li>
      <li >
        <a [routerLink]="['reportes/gestion-vales/']" routerLinkActive="activeItem" >
          <i class="bx bxs-bar-chart-square"></i>
          <span>Gestión de Vales</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a [routerLink]="['reportes/vales-asignados/']" routerLinkActive="activeItem" >
          <i class="bx bxs-bar-chart-square"></i>
          <span>Vales Asignados</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a [routerLink]="['reportes/vales-designados/']" routerLinkActive="activeItem" >
          <i class="bx bxs-bar-chart-square"></i>
          <span>Vales Designados</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a [routerLink]="['reportes/reporte-carta-ritmica/']" routerLinkActive="activeItem" >
          <i class="bx bxs-bar-chart-square"></i>
          <span>Reporte Carta Rítmica</span>
        </a>
      </li>
      <li class="menu-title" *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'" >Mantenedores</li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
        <a [routerLink]="['mantenedores/empresas/']" routerLinkActive="activeItem" >
          <i class="bx bxs-factory"></i>
          <span>Empresas</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a [routerLink]="['/mantenedores/trabajadores']"
             routerLinkActive="activeItem" >
            <i class="fa fa-users"></i> <span>Trabajadores</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a [routerLink]="['/mantenedores/contratistas']"
             routerLinkActive="activeItem" >
            <i class="fa fa-users-cog"></i> <span>Contratistas</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
        <a [routerLink]="['/mantenedores/tipo-proyectos']"
             routerLinkActive="activeItem" >
           <i class="fa fa-briefcase"></i>
            <span>Tipo de proyectos</span>
        </a>
      </li>
<!--      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">-->
<!--        <a [routerLink]="['/mantenedores/unidad-medidas']"-->
<!--             routerLinkActive="activeItem" >-->
<!--            <i class="fa fa-balance-scale"></i>-->
<!--            <span>Unidad de medidas</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">-->
<!--        <a [routerLink]="['/mantenedores/unidades-medibles']"-->
<!--             routerLinkActive="activeItem" >-->
<!--          <i class="fa fa-balance-scale-left"></i>-->
<!--          <span>Unidades medibles</span>-->
<!--        </a>-->
<!--      </li>-->
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'ADMIN-OBRA_ROLE'">
        <a [routerLink]="['/mantenedores/cargos']"
           routerLinkActive="activeItem" >
          <i class="fa fa-user-check"></i>
          <span>Cargos</span>
        </a>
      </li>
      <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
        <a [routerLink]="['/mantenedores/unidad-negocios']"
           routerLinkActive="activeItem" >
          <i class="bx bx-award"></i>
          <span>Unidad de negocios</span>
        </a>
      </li>

<!--      <ng-container *ngFor="let item of menuItems">-->
<!--        <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>-->
<!--        &lt;!&ndash; Layouts menu &ndash;&gt;-->
<!--        <li *ngIf="item.isLayout">-->
<!--          <a href="javascript: void(0);" class="has-arrow">-->
<!--            <i class="bx bx-layout"></i>-->
<!--            <span>{{'MENUITEMS.LAYOUTS.TEXT' | translate}} +++</span>-->
<!--          </a>-->
<!--          <ul class="sub-menu" aria-expanded="false">-->
<!--            <li><a (click)="changeLayout('horizontal')"-->
<!--                href="javascript: void(0);">{{ 'MENUITEMS.LAYOUTS.LIST.HORIZONTAL' | translate}} </a></li>-->
<!--            <li><a (click)="lightSidebar()"-->
<!--                href="javascript: void(0);">{{ 'MENUITEMS.LAYOUTS.LIST.LIGHTSIDEBAR' | translate}}</a></li>-->
<!--            <li><a (click)="compactSidebar()"-->
<!--                href="javascript: void(0);">{{ 'MENUITEMS.LAYOUTS.LIST.COMPACTSIDEBAR' | translate}}</a></li>-->
<!--            <li><a (click)="iconSidebar()"-->
<!--                href="javascript: void(0);">{{ 'MENUITEMS.LAYOUTS.LIST.ICONSIDEBAR' | translate}}</a></li>-->
<!--            <li><a (click)="boxedLayout()"-->
<!--                href="javascript: void(0);">{{ 'MENUITEMS.LAYOUTS.LIST.BOXED' | translate}}</a></li>-->
<!--            <li><a (click)="coloredSidebar()"-->
<!--                href="javascript: void(0);">{{ 'MENUITEMS.LAYOUTS.LIST.COLOREDSIDEBAR' | translate}}</a></li>-->
<!--            <li><a (click)="scrollable()" href="javascript: void(0);"> Scrollable </a></li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        &lt;!&ndash; end Layout menu &ndash;&gt;-->

<!--        <li *ngIf="!item.isTitle && !item.isLayout">-->
<!--          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"-->
<!--            [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">-->
<!--            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>-->
<!--            <span> {{ item.label | translate }}</span>-->
<!--            <span class="badge badge-pill badge-{{item.badge.variant}} float-right"-->
<!--              *ngIf="item.badge">{{item.badge.text | translate}}</span>-->
<!--          </a>-->

<!--          <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">-->
<!--            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>-->
<!--            <span> {{  item.label  | translate }}</span>-->
<!--            <span class="badge badge-pill badge-{{item.badge.variant}} float-right"-->
<!--              *ngIf="item.badge">{{item.badge.text | translate}}</span>-->
<!--          </a>-->

<!--          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">-->
<!--            <li *ngFor="let subitem of item.subItems">-->
<!--              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"-->
<!--                [attr.data-parent]="subitem.parentId" routerLinkActive="active">-->
<!--                {{ subitem.label | translate}}-->
<!--              </a>-->
<!--              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"-->
<!--                [attr.data-parent]="subitem.parentId">-->
<!--                {{ subitem.label | translate}}-->
<!--              </a>-->
<!--              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">-->
<!--                <li *ngFor="let subSubitem of subitem.subItems">-->
<!--                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"-->
<!--                    class="side-nav-link-ref">-->
<!--                    {{ subSubitem.label | translate }}-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
<!--      </ng-container>-->
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <perfect-scrollbar [config]="configData">
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </perfect-scrollbar>
</div>
<!-- Left Sidebar End -->
