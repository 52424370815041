<!--<div class="row">-->
<!--    <div class="col-md-12">-->
<!--        <div class="page-title-box d-flex align-items-center justify-content-between">-->
<!--            <div class="row">-->
<!--                <div class="col-md-6 col-sm-12">-->
<!--                    <h4 class="mb-0 font-size-18 "><i class="{{icon}}"></i> {{ label }}</h4>-->
<!--                </div>-->
<!--                <div class="page-title-right col-md-6 col-sm-12">-->
<!--                    <ol class="breadcrumb m-0 col-md-12">-->
<!--                        <li class="breadcrumb-item" ><a ><i class="bx bxs-home"></i> Inicio</a>-->
<!--                        </li>-->
<!--                        <li class="breadcrumb-item" ><a >{{pages}}</a>-->
<!--                        </li>-->
<!--                        <li class="breadcrumb-item active" >{{label}}-->
<!--                        </li>-->
<!--                    </ol>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<div class="row">
    <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18"><i class="{{icon}}"></i> {{ label }}</h4>
            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                    <ng-container >
                        <li class="breadcrumb-item" ><a ><i class="bx bx-home"></i> Inicio</a></li>
                        <li class="breadcrumb-item" ><a ><i class=""></i> {{pages}}</a></li>
                        <li class="breadcrumb-item active" >{{ label }}
                        </li>
                    </ng-container>
                </ol>
            </div>
        </div>
    </div>
</div>
