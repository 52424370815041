import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UsuarioService} from '../services/admin/usuario/usuario.service';

@Injectable({
    providedIn: 'root'
})
export class VerificaTokenGuard implements CanActivate {
    constructor(
        public usuarioService: UsuarioService,
        public router: Router
    ) { }

    canActivate(): Promise<boolean> | boolean {
        const token = localStorage.getItem('token');
        // console.warn('token: ' + token);
        if (!token) {
            console.warn('token inexistente');
            this.router.navigate(['/auth/login']);
            return false;
        }
        const payload = JSON.parse( atob( token.split( '.')[1]));
        const expirado = this.expirado( payload.exp, payload.usuario.id );
        // console.log(payload);
        if ( expirado ) {
            console.log('token expirado o inexistente');
            this.router.navigate(['/auth/login']);
            return false;
        }
        return this.verificaRenueva( payload.exp );
    }

    verificaRenueva( fechaExp: number ): Promise<boolean>  {

        return new Promise( (resolve, reject) => {

            const tokenExp = new Date( fechaExp * 1000 );
            const ahora = new Date();

            ahora.setTime( ahora.getTime() + ( 1 * 60 * 60 * 1000 ) );

            console.warn( tokenExp );
            console.warn( ahora );

            if ( tokenExp.getTime() > ahora.getTime() ) {
                resolve(true);
            } else {
                this.router.navigate(['/auth/login']);
                // this.usuarioService.renuevaToken()
                //     .subscribe( () => {
                //       resolve(true);
                //     }, () => {
                //       this.router.navigate(['/login']);
                //       reject(false);
                //     });

            }

        });

    }

    expirado( fechaExp: number , usuario: number) {
        const ahora = new Date().getTime() / 1000;
        // console.log('verificacion de token expirado, fecha: ' + fechaExp + ', ahora: ' + ahora);

        if ( fechaExp < ahora ) {
            console.error('token expirado');
            return true;
        } else {
            // console.log('token valido');
            // return false;
            /*--- valida usuario ---*/
            if (usuario > 0) {
                return false;
            } else {
                console.error('Usuario no autorizado');
                return true;
            }
        }
    }

}
